// frontend/src/config/api.js

const API_CONFIG = {
  BASE_URL: process.env.REACT_APP_API_URL || 'http://localhost:5002',
  ENDPOINTS: {
    GOOGLE_AUTH: '/api/auth/google',
    MIGRATE_DOCS: '/api/auth/migrate-docs',
    USAGE: '/api/usage',
    WORD_LIMITS: '/api/word-limits',
    REQUEST_TEXT_UPDATE: '/api/request-text-update',
    RECENT_DOCS: '/api/recent-docs',
    DELETE_DOCUMENT: '/api/delete-document',
    GET_DOCUMENT: '/api/get-document',
    EXTRACT_TEXT: '/api/extract',
    DOWNLOAD_DOCX: '/api/download-docx',
    DOWNLOAD_MD: '/api/download-md',
    SOCKET_FORMAT: '/format'
  }
};
  
export const getApiUrl = (endpoint) => `${API_CONFIG.BASE_URL}${endpoint}`;
export default API_CONFIG;