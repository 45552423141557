// src/components/ui/Toggle.jsx

import React from 'react';

const Toggle = ({ isOn, onToggle }) => {
  return (
    <button
      onClick={onToggle}
      className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none ${
        isOn ? 'bg-purple-600' : 'bg-gray-300'
      }`}
      aria-pressed={isOn}
    >
      <span
        className={`transform transition-transform inline-block w-4 h-4 bg-white rounded-full ${
          isOn ? 'translate-x-6' : 'translate-x-1'
        }`}
      />
    </button>
  );
};

export default Toggle;