import toast from 'react-hot-toast';

const useToast = () => {
  const success = (message) => {
    toast.success(message, {
      style: {
        background: '#10B981',
        color: '#fff',
        fontWeight: 500,
      },
      iconTheme: {
        primary: '#fff',
        secondary: '#10B981',
      },
    });
  };

  const error = (message) => {
    toast.error(message, {
      style: {
        background: '#EF4444',
        color: '#fff',
        fontWeight: 500,
      },
      iconTheme: {
        primary: '#fff',
        secondary: '#EF4444',
      },
    });
  };

  const info = (message) => {
    toast(message, {
      style: {
        background: '#6366F1',
        color: '#fff',
        fontWeight: 500,
      },
      icon: '📝',
    });
  };

  const loading = (message) => {
    return toast.loading(message, {
      style: {
        background: '#6B7280',
        color: '#fff',
        fontWeight: 500,
      },
    });
  };

  return {
    success,
    error,
    info,
    loading,
    dismiss: toast.dismiss,
  };
};

export default useToast; 