// File: src/components/formatter/ProFeaturesPromo.jsx

import React from 'react';
import { motion } from 'framer-motion';

const ProFeaturesPromo = () => {
  return (
    <section className="py-8 bg-gray-50 dark:bg-gray-800 mt-12">
      <div className="container mx-auto px-4 text-center">
        <motion.h3
          className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white mb-4"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Explore Pro Features
        </motion.h3>
        <motion.p
          className="text-gray-600 dark:text-gray-300 mb-6 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          viewport={{ once: true }}
        >
          Unlock AI Image Captions, Drive Sync, Higher Word Limits, and more advanced tools to supercharge your workflow.
        </motion.p>
        <motion.button
          className="px-6 py-3 bg-purple-600 text-white font-semibold rounded-md hover:bg-purple-700 transition duration-300"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
          viewport={{ once: true }}
        >
          Learn More
        </motion.button>
      </div>
    </section>
  );
};

export default ProFeaturesPromo;