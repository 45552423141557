import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Sidebar = ({ 
  isOpen, 
  handleClose, 
  title, 
  children, 
  position = 'right' // 'left' or 'right'
}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, handleClose]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.aside
          initial={{ x: position === 'right' ? '100%' : '-100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: position === 'right' ? '100%' : '-100%', opacity: 0 }}
          transition={{ type: 'tween', duration: 0.3 }}
          className={`fixed top-0 ${position}-0 w-80 h-full bg-white dark:bg-gray-800 shadow-lg z-50`}
        >
          {/* Header */}
          <div className="flex justify-between items-center p-4 border-b border-purple-200 dark:border-gray-700 bg-purple-50 dark:bg-gray-700">
            <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-100">
              {title}
            </h2>
            <button
              onClick={handleClose}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200"
              aria-label={`Close ${title}`}
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>

          {/* Content */}
          <div className="p-4 overflow-y-auto">
            {children}
          </div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;