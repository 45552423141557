// src/pages/FormatterPage.jsx

import React, { useContext } from 'react';
import TaglineSection from '../components/formatter/LandingUI/TaglineSection';
import MainActionArea from '../components/formatter/LandingUI/MainActionArea';
import RecentDocsCarousel from '../components/formatter/LandingUI/RecentDocsCarousel';
import ProFeaturesPromo from '../components/formatter/LandingUI/ProFeaturesPromo';
import ServerErrorPage from '../components/ui/ServerErrorPage';
import { AuthContext } from '../contexts/AuthContext';

const FormatterPage = () => {
  const { serverError } = useContext(AuthContext);

  if (serverError) {
    return (
      <ServerErrorPage>
        <TaglineSection />
      </ServerErrorPage>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      {/* Premium vibe tagline */}
      <TaglineSection />

      {/* Main action area */}
      <MainActionArea />

      {/* Recent docs carousel or placeholder if not authed */}
      <RecentDocsCarousel />

      {/* Pro features promo */}
      <ProFeaturesPromo />
    </div>
  );
};

export default FormatterPage;