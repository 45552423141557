// src/contexts/SessionContext.js

import React, { createContext, useState, useMemo } from 'react';

export const SessionContext = createContext();

const SessionProvider = ({ children }) => {
  const [documentId, setDocumentId] = useState(null);
  const [mostRecentText, setMostRecentText] = useState(null); // Added
  const [settings, setSettings] = useState({ // Added
    h1Style: 'none',
    h2Style: 'none',
    h3Style: 'none',
    multiLevelHeadings: false,
  });

  const clearSession = () => {
    setDocumentId(null);
    setMostRecentText(null);
    setSettings({ // Clear settings on session clear
      h1Style: 'none',
      h2Style: 'none',
      h3Style: 'none',
      multiLevelHeadings: false,
    });
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    documentId,
    setDocumentId,
    clearSession,
    mostRecentText,       // Expose mostRecentText
    setMostRecentText,    // Expose setMostRecentText
    settings,             // Expose settings
    setSettings,          // Expose setSettings
  }), [documentId, mostRecentText, settings]);

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;