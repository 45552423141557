// File: src/components/formatter/OutputSection/Dropdown.jsx

import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

const Dropdown = ({ label, options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  const toggleDropdown = () => setIsOpen(prev => !prev);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) document.addEventListener('mousedown', handleClickOutside);
    else document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const renderOption = (option, index) => {
    const isObject = typeof option === 'object';
    const label = isObject ? option.label : option;
    const disabled = isObject ? option.disabled : false;
    const tooltip = isObject ? option.tooltip : null;

    return (
      <div key={index} className="relative group">
        <button
          onClick={() => {
            if (!disabled) {
              onSelect(label);
              setIsOpen(false);
            }
          }}
          className={`w-full text-left px-4 py-2 text-sm 
            ${disabled 
              ? 'text-gray-400 dark:text-gray-500 cursor-not-allowed' 
              : 'text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700'
            }`}
          disabled={disabled}
        >
          {label}
        </button>
        {tooltip && disabled && (
          <div className="absolute left-full ml-2 top-1/2 -translate-y-1/2 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300">
            <div className="bg-gray-900 text-white text-sm rounded-lg py-2 px-3 shadow-lg">
              <div className="relative">
                {tooltip}
                <div className="absolute w-2 h-2 bg-gray-900 transform rotate-45 -left-1 top-1/2 -translate-y-1/2"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-all inline-flex items-center"
      >
        {label}
        <ChevronDownIcon className="ml-2 h-5 w-5" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: -10 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: -10 }}
            transition={{ duration: 0.2 }}
            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-50"
          >
            <div className="py-1">
              {options.map((option, index) => renderOption(option, index))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dropdown;