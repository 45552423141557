// File: src/components/formatter/FormatButton.jsx

import React from 'react';
import Button from '../ui/Button';

const FormatButton = ({ onClick, disabled, isLoading }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className="mt-4"
    >
      {isLoading ? 'Formatting...' : 'Format'}
    </Button>
  );
};

export default FormatButton;