// src/App.js

import { GoogleOAuthProvider } from '@react-oauth/google';
import { Routes, Route } from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import AppNavbar from './components/layout/Navbar';
import HomePage from './pages/HomePage';
import FormatterPage from './pages/FormatterPage';
import ExtensionPage from './pages/ExtensionPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsPage from './pages/TermsPage';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AuthProvider from './contexts/AuthContext';
import SessionProvider from './contexts/SessionContext'; // Import SessionProvider
import FingerprintJS from '@fingerprintjs/fingerprintjs';

function App() {
  // Define the initialization function outside useEffect
  const initializeFingerprint = async () => {
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const uniqueBrowserId = result.visitorId;
      // Store in localStorage if not already present
      if (!localStorage.getItem('uniqueBrowserId')) {
        localStorage.setItem('uniqueBrowserId', uniqueBrowserId);
      }
    } catch (error) {
      console.error('FingerprintJS initialization failed:', error);
    }
  };

  useEffect(() => {
    // Initialize AOS with disabled on mobile and once: true to prevent re-animation
    AOS.init({
      duration: 800,
      once: true,
      disable: 'mobile', // Disable on mobile devices
      startEvent: 'load', // Only start animations after everything has loaded
      offset: 0, // Prevent any offset triggers
      disableMutationObserver: true // Disable mutation observer to prevent unwanted updates
    });

    // Call the initialization function
    initializeFingerprint();
  }, []);

  return (
    <GoogleOAuthProvider clientId="964882969359-5rdctd02mphkoog2vnqr895qlr8sdagk.apps.googleusercontent.com">
      <AuthProvider>
        <SessionProvider> {/* Wrap with SessionProvider */}
          <Router>
            <div className="flex flex-col min-h-screen bg-purple-50 dark:bg-gray-900">
              {/* Toast Container */}
              <Toaster
                position="bottom-center"
                toastOptions={{
                  duration: 4000,
                  style: {
                    borderRadius: '10px',
                    padding: '16px',
                    maxWidth: '400px',
                    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                  },
                }}
              />
              
              {/* Navbar */}
              <AppNavbar />

              {/* Main Content */}
              <main className="flex-grow px-12 py-6 pb-24">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/formatter" element={<FormatterPage />} />
                  <Route path="/extension" element={<ExtensionPage />} />
                  <Route path="/privacy" element={<PrivacyPolicyPage />} />
                  <Route path="/terms" element={<TermsPage />} />
                </Routes>
              </main>
            </div>
          </Router>
        </SessionProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;