// File: src/components/formatter/TaglineSection.jsx

import React from 'react';
import { motion } from 'framer-motion';

const TaglineSection = () => {
  return (
    <section 
      className="flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-16 px-4"
    >
      <motion.h1
        className="text-3xl md:text-5xl font-bold text-center max-w-3xl leading-snug"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Elevate Your Brilliance with <span className="text-yellow-200">Seamless Formatting</span>
      </motion.h1>
    </section>
  );
};

export default TaglineSection;