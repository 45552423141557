// src/components/formatter/OutputSection/TextSettingsSidebar.jsx

import React, { useContext } from 'react';
import Sidebar from '../../ui/Sidebar';
import Dropdown from '../../ui/Dropdown';
import Toggle from '../../ui/Toggle';
import { SessionContext } from '../../../contexts/SessionContext';
import { AuthContext } from '../../../contexts/AuthContext'; // Import AuthContext
import axios from 'axios'; // Import axios
import { getApiUrl } from '../../../config/api'; // Import getApiUrl
import API_CONFIG from '../../../config/api';
import useToast from '../../../utils/useToast'; // Import useToast

const TextSettingsSidebar = ({ isOpen, handleClose }) => {
  const { settings, setSettings, documentId, setMostRecentText } = useContext(SessionContext); // Use shared settings
  const { user, fingerprintId } = useContext(AuthContext); // Access user and fingerprintId from AuthContext
  const toast = useToast(); // Initialize toast

  const numberingOptions = ['none', 'numeric', 'alphabetic', 'roman'];

  // Handler to update settings and make backend request
  const handleSettingChange = async (key, value) => {
    const updatedSettings = {
      ...settings,
      [key]: value,
    };
    setSettings(updatedSettings); // Update shared settings

    // Prepare payload
    const payload = {
      doc_id: documentId,
      user_id: user ? user.id : null,
      fingerprint_id: !user && fingerprintId ? fingerprintId : null, // Include fingerprintId if user is not authenticated
      settings_object: updatedSettings,
      auth: !!user,
    };

    try {
      const response = await axios.post(getApiUrl(API_CONFIG.ENDPOINTS.REQUEST_TEXT_UPDATE), payload);
      console.log('Backend response:', response.data);
      
      if (response.data.updated_text) {
        setMostRecentText(response.data.updated_text); // Update mostRecentText in SessionContext
        toast.success('Text settings updated successfully');
      }
    } catch (error) {
      console.error('Error updating text settings:', error);
      toast.error('Failed to update text settings');
    }
  };

  return (
    <Sidebar
      isOpen={isOpen}
      handleClose={handleClose}
      title="Text Settings"
      position="left"
      className="bg-gradient-to-br from-purple-100 to-purple-50 dark:from-gray-900 dark:to-gray-800 shadow-lg rounded-lg p-6"
    >
      <div className="space-y-6">
        {/* H1 Numbering Style */}
        <div className="flex flex-col space-y-2">
          <label className="text-sm font-medium text-gray-700 dark:text-gray-200">
            H1 Numbering Style
          </label>
          <Dropdown
            label={settings.h1Style}
            options={numberingOptions}
            onSelect={(option) => handleSettingChange('h1Style', option)}
            className="w-full"
          />
        </div>

        {/* H2 Numbering Style */}
        <div className="flex flex-col space-y-2">
          <label className="text-sm font-medium text-gray-700 dark:text-gray-200">
            H2 Numbering Style
          </label>
          <Dropdown
            label={settings.h2Style}
            options={numberingOptions}
            onSelect={(option) => handleSettingChange('h2Style', option)}
            className="w-full"
          />
        </div>

        {/* H3 Numbering Style */}
        <div className="flex flex-col space-y-2">
          <label className="text-sm font-medium text-gray-700 dark:text-gray-200">
            H3 Numbering Style
          </label>
          <Dropdown
            label={settings.h3Style}
            options={numberingOptions}
            onSelect={(option) => handleSettingChange('h3Style', option)}
            className="w-full"
          />
        </div>

        {/* Multi-Level Headings Toggle */}
        <div className="flex items-center space-x-3">
          <Toggle
            isOn={settings.multiLevelHeadings}
            onToggle={() => handleSettingChange('multiLevelHeadings', !settings.multiLevelHeadings)}
          />
          <label className="text-sm font-medium text-gray-700 dark:text-gray-200">
            Enable Multi-Level Headings
          </label>
        </div>
      </div>
    </Sidebar>
  );
};

export default TextSettingsSidebar;