// File: src/components/formatter/OutputSection/TableOfContentSidebar.jsx

import React from 'react';
import Sidebar from '../../ui/Sidebar';

const TableOfContentSidebar = ({ isOpen, handleClose, tocItems }) => {
  return (
    <Sidebar
      isOpen={isOpen}
      handleClose={handleClose}
      title="Table of Contents"
      position="right"
    >
      <ul className="space-y-3">
        {tocItems.map((item, index) => (
          <li key={index}>
            <a
              href={`#section-${index}`}
              className="block text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors duration-200"
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
    </Sidebar>
  );
};

export default TableOfContentSidebar;