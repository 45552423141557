// File: src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import API_CONFIG, { getApiUrl } from '../config/api';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [fingerprintId, setFingerprintId] = useState(null);
  const [usage, setUsage] = useState({ word_count: 0, days_until_reset: 7 });
  const [wordLimits, setWordLimits] = useState({
    UNAUTHENTICATED_LIMIT: 0,
    AUTHENTICATED_LIMIT: 0,
  });
  const [serverError, setServerError] = useState(false);

  // Initialize from localStorage - only get user data without usage
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedFingerprintId = localStorage.getItem('uniqueBrowserId');
    
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      // Remove usage from stored user data
      const { usage: _, ...userWithoutUsage } = parsedUser;
      setUser(userWithoutUsage);
    }
    
    if (storedFingerprintId) {
      setFingerprintId(storedFingerprintId);
    }
  }, []);

  const fetchUsage = useCallback(async (user_id = null, fingerprint_id = null) => {
    if (!fingerprint_id && !user_id) return;
    
    try {
      setServerError(false);
      const response = await axios.get(getApiUrl(API_CONFIG.ENDPOINTS.USAGE), {
        params: { user_id, fingerprint_id }
      });
      
      if (response.data) {
        const newUsage = {
          word_count: response.data.weekly_usage || 0,
          days_until_reset: response.data.days_until_reset || 7
        };
        
        setUsage(newUsage);
      }
    } catch (error) {
      console.error('Error fetching usage:', error);
      setServerError(true);
    }
  }, []); 

  // Fetch initial usage
  useEffect(() => {
    if (fingerprintId) {
      fetchUsage(user?.id || null, fingerprintId);
    }
  }, [fingerprintId, user?.id, fetchUsage]);

  // Fetch word limits
  useEffect(() => {
    const fetchWordLimits = async () => {
      try {
        setServerError(false);
        const response = await axios.get(getApiUrl(API_CONFIG.ENDPOINTS.WORD_LIMITS));
        setWordLimits(response.data);
      } catch (error) {
        console.error('Failed to fetch word limits:', error);
        setServerError(true);
      }
    };

    fetchWordLimits();
  }, []);

  const login = useCallback(async (userData) => {
    // Remove usage from userData before storing
    const { usage: _, ...userWithoutUsage } = userData;
    
    localStorage.setItem('user', JSON.stringify(userWithoutUsage));
    setUser(userWithoutUsage);
    
    if (fingerprintId) {
      await fetchUsage(userWithoutUsage.id, fingerprintId);
    }
  }, [fingerprintId, fetchUsage]);

  const logout = useCallback(async () => {
    localStorage.removeItem('user');
    setUser(null);
    
    if (fingerprintId) {
      await fetchUsage(null, fingerprintId);
    } else {
      setUsage({ word_count: 0, days_until_reset: 7 });
    }
  }, [fingerprintId, fetchUsage]);

  const updateUsage = useCallback(async (additionalWords) => {
    try {
      const currentUser = user;
      const response = await axios.post(getApiUrl(API_CONFIG.ENDPOINTS.USAGE), {
        user_id: currentUser?.id || null,
        fingerprint_id: !currentUser ? fingerprintId : null,
        words: additionalWords
      });
      
      if (response.status === 200) {
        await fetchUsage(currentUser?.id || null, !currentUser ? fingerprintId : null);
      }
    } catch (error) {
      console.error('Error updating usage:', error);
      throw error;
    }
  }, [user, fingerprintId, fetchUsage]);

  return (
    <AuthContext.Provider value={{ 
      user, 
      fingerprintId,
      login, 
      logout, 
      usage, 
      updateUsage, 
      fetchUsage, 
      wordLimits,
      serverError 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;