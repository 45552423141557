// src/components/formatter/PasteModal.jsx

import React, { useState, useContext } from 'react';
import Modal from '../ui/Modal';
import FormatButton from './FormatButton';
import { AuthContext } from '../../contexts/AuthContext';
import { SessionContext } from '../../contexts/SessionContext'; // Import SessionContext
import { io } from 'socket.io-client';
import { getApiUrl } from '../../config/api';
import API_CONFIG from '../../config/api';

const PasteModal = ({ show, handleClose, setOutputData }) => {
  const { user, fingerprintId, wordLimits, fetchUsage } = useContext(AuthContext);
  const { setDocumentId } = useContext(SessionContext); // Access setDocumentId
  const [text, setText] = useState('');
  const [isFormatting, setIsFormatting] = useState(false);
  const [error, setError] = useState('');

  const wordCount = text.trim().split(/\s+/).filter(word => word.length > 0).length;

  const handleFormat = () => {
    setIsFormatting(true);
    setError('');
    handleClose();
    let formattedContent = '';
    
    // Initialize Socket.IO connection
    const socket = io(getApiUrl(API_CONFIG.ENDPOINTS.SOCKET_FORMAT), {
      transports: ['websocket'],
      withCredentials: true
    });

    socket.on('connect', () => {
      console.log('Connected to formatting socket');
      // Send the text to be formatted along with user identification
      socket.emit('format', { 
        text,
        user_id: user?.id || null,
        fingerprint_id: !user ? fingerprintId : null
      });
    });

    socket.on('formatted_chunk', (data) => {
      formattedContent += data.chunk;
      // Update the output in real-time
      setOutputData({
        title: 'Formatted Text',
        content: formattedContent,
      });
    });

    socket.on('document_id', (data) => {
      console.log('Document ID received:', data.document_id);
      setDocumentId(data.document_id); // Set document ID in SessionContext
      // You might want to store this document_id in state or use it as needed
    });

    socket.on('done', async () => {
      socket.disconnect();
      setIsFormatting(false);
      // Fetch updated usage after formatting is complete
      try {
        await fetchUsage(user?.id, !user ? fingerprintId : null);
      } catch (err) {
        console.error('Failed to fetch updated usage:', err);
        setError('Failed to update usage statistics');
      }
    });

    socket.on('error', (error) => {
      console.error('Formatting error:', error);
      setError(error.error || 'An error occurred during formatting');
      setIsFormatting(false);
      socket.disconnect();
    });
  };

  return (
    <>
      {!show ? null : (
        <Modal show={show} handleClose={handleClose} title="Paste Your Text">
          <textarea
            className="w-full h-40 p-4 border border-gray-300 dark:border-gray-700 rounded-md resize-none bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-100"
            placeholder="Paste your text here..."
            value={text}
            onChange={(e) => setText(e.target.value)}
          ></textarea>
          <div className="flex flex-col space-y-2 mt-2">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Word Count: {wordCount} / {user ? wordLimits.AUTHENTICATED_LIMIT : wordLimits.UNAUTHENTICATED_LIMIT}
              </span>
              <span className="text-sm text-red-500">
                Note: Images are not supported in paste text. Please upload a DOCX file if your document contains images.
              </span>
            </div>
            {error && (
              <div className="text-sm text-red-500">
                {error}
              </div>
            )}
          </div>
          <FormatButton 
            onClick={handleFormat} 
            disabled={wordCount === 0 || isFormatting} 
            isLoading={isFormatting}
          />
        </Modal>
      )}
    </>
  );
};

export default PasteModal;