import React from 'react';

const ServerErrorPage = ({ children }) => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      {/* Allow injection of content before error message (like tagline) */}
      {children}

      {/* Server error message */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16 text-center">
        <div className="bg-red-50 dark:bg-red-900/20 rounded-2xl p-8 shadow-sm">
          <svg
            className="mx-auto h-12 w-12 text-red-500 dark:text-red-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <h3 className="mt-6 text-xl font-semibold text-gray-900 dark:text-gray-100">
            We'll be back soon!
          </h3>
          <p className="mt-4 text-gray-600 dark:text-gray-300">
            Our servers are taking a quick break. Please check back in a few minutes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServerErrorPage; 