// frontend/src/components/formatter/LandingUI/RecentDocsCarousel.jsx

import React, { useEffect, useState, useContext } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import API_CONFIG, { getApiUrl } from '../../../config/api';
import { AuthContext } from '../../../contexts/AuthContext';
import { SessionContext } from '../../../contexts/SessionContext';
import useToast from '../../../utils/useToast';

const RecentDocsCarousel = () => {
  const { user, fingerprintId } = useContext(AuthContext);
  const { documentId, setDocumentId, setMostRecentText } = useContext(SessionContext);
  const [recentDocs, setRecentDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const limit = 10; // Number of recent documents to fetch

  const fetchRecentDocs = async () => {
    if (!user?.id) {
      // If no authenticated user, don't fetch docs
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    
    try {
      const params = {
        user_id: user.id,
        limit
      };

      const response = await axios.get(getApiUrl(API_CONFIG.ENDPOINTS.RECENT_DOCS), { params });
      
      if (response.data && response.data.recent_docs) {
        setRecentDocs(response.data.recent_docs);
      } else {
        setRecentDocs([]);
      }
    } catch (err) {
      console.error('Error fetching recent documents:', err);
      setError('Failed to load recent documents.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Only fetch if we have an authenticated user
    if (user?.id) {
      fetchRecentDocs();
    } else {
      setLoading(false);
    }
  }, [user, documentId]); // Re-fetch when user or documentId changes

  const handleDelete = async (docId) => {
    if (!user?.id) return;

    const loadingToast = toast.loading('Deleting document...');
    try {
      const payload = {
        doc_id: docId,
        auth: true,
        user_id: user.id
      };

      await axios.delete(getApiUrl(API_CONFIG.ENDPOINTS.DELETE_DOCUMENT), { data: payload });
      
      toast.dismiss(loadingToast);
      toast.success('Document deleted successfully');
      
      fetchRecentDocs();
    } catch (err) {
      console.error('Error deleting document:', err);
      toast.dismiss(loadingToast);
      toast.error('Failed to delete document');
      setError('Failed to delete document.');
    }
  };

  const handleOpenDocument = async (docId) => {
    const loadingToast = toast.loading('Opening document...');
    try {
      const response = await axios.get(getApiUrl(API_CONFIG.ENDPOINTS.GET_DOCUMENT), {
        params: {
          doc_id: docId,
          auth: !!user
        }
      });

      if (response.data) {
        // Update session context with the document data
        setDocumentId(docId);
        setMostRecentText(response.data.md_formatted_text);
        
        toast.dismiss(loadingToast);
        toast.success('Document opened successfully');
      }
    } catch (err) {
      console.error('Error opening document:', err);
      toast.dismiss(loadingToast);
      toast.error('Failed to open document');
    }
  };

  const renderLoadingState = () => (
    <section className="container mx-auto py-8 px-4">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">
        Recent Documents
      </h2>
      <div className="flex space-x-4 overflow-x-auto pb-4">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="min-w-[300px] h-[200px] bg-gray-100 dark:bg-gray-800 rounded-xl shadow-md p-6 flex-shrink-0 animate-pulse"
          />
        ))}
      </div>
    </section>
  );

  // Show sign in prompt for unauthenticated users
  if (!user?.id) {
    return (
      <section className="container mx-auto py-8 px-4">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">
          Recent Documents
        </h2>
        <div className="bg-gray-50 dark:bg-gray-800 rounded-xl p-8 text-center">
          <p className="text-gray-600 dark:text-gray-300 mb-4">Sign in to track and access your document history!</p>
          <p className="text-sm text-gray-500 dark:text-gray-400">Your document history will be saved and synced across devices when you sign in.</p>
        </div>
      </section>
    );
  }

  if (loading) return renderLoadingState();
  if (error) {
    return (
      <section className="container mx-auto py-8 px-4">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">
          Recent Documents
        </h2>
        <p className="text-red-500">{error}</p>
      </section>
    );
  }

  if (recentDocs.length === 0) {
    return (
      <section className="container mx-auto py-8 px-4">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">
          Recent Documents
        </h2>
        <div className="bg-gray-50 dark:bg-gray-800 rounded-xl p-8 text-center">
          <p className="text-gray-600 dark:text-gray-300">No documents yet. Start by formatting your first document!</p>
        </div>
      </section>
    );
  }

  return (
    <section className="container mx-auto py-8 px-4">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">
        Recent Documents
      </h2>
      <div className="flex space-x-6 overflow-x-auto pb-6 scrollbar-thin scrollbar-thumb-purple-600 scrollbar-track-gray-100 dark:scrollbar-track-gray-800">
        {recentDocs.map((doc, index) => (
          <motion.div
            key={index}
            className="min-w-[300px] bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 flex-shrink-0 flex flex-col justify-between border border-gray-100 dark:border-gray-700"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            viewport={{ once: true }}
          >
            <div>
              <div className="flex items-center mb-4">
                <div className="w-8 h-8 bg-purple-100 dark:bg-purple-900 rounded-lg flex items-center justify-center mr-3">
                  <svg className="w-4 h-4 text-purple-600 dark:text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </div>
                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 truncate">
                  {doc.filename}
                </h3>
              </div>
              <div className="bg-gray-50 dark:bg-gray-900 rounded-lg p-3 mb-4">
                <pre className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-wrap break-words snippet max-h-20 overflow-hidden">
                  {doc.snippet}
                </pre>
              </div>
            </div>
            <div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mb-3">
                {new Date(doc.dateFormatted).toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              </p>
              <div className="flex gap-2">
                <button 
                  className="px-4 py-2.5 bg-red-100 hover:bg-red-200 dark:bg-red-900/30 dark:hover:bg-red-900/50 text-red-600 dark:text-red-400 text-sm rounded-lg transition-colors duration-200 flex items-center justify-center"
                  onClick={() => handleDelete(doc.id)}
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
                <button 
                  className="flex-1 px-4 py-2.5 bg-purple-600 hover:bg-purple-700 text-white text-sm rounded-lg transition-colors duration-200 flex items-center justify-center gap-2"
                  onClick={() => handleOpenDocument(doc.id)}
                >
                  <span>Open Document</span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default RecentDocsCarousel;