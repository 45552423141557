// src/components/formatter/OutputSection/OutputSection.jsx

import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import {
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
  MagnifyingGlassIcon,
  BookmarkIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/react/24/outline';
import Dropdown from '../../ui/Dropdown';
import TableOfContentSidebar from './TableOfContentSidebar';
import TextSettingsSidebar from './TextSettingsSidebar';
import MarkdownRenderer from './MarkdownRenderer';
import { SessionContext } from '../../../contexts/SessionContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { marked } from 'marked';
import useToast from '../../../utils/useToast';
import axios from 'axios';
import API_CONFIG, { getApiUrl } from '../../../config/api';

const OutputSection = ({ title, content, isStreaming }) => {
  const { mostRecentText, settings, documentId, setMostRecentText } = useContext(SessionContext);
  const { user, fingerprintId } = useContext(AuthContext);
  const [isTOCOpen, setTOCOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const contentRef = useRef(null);
  const toast = useToast();
  const previousStreamingRef = useRef(isStreaming);

  useLayoutEffect(() => {
    if (isStreaming && contentRef.current) {
      const debouncedScrollToBottom = debounce(() => {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }, 50);
      debouncedScrollToBottom();
    }
  }, [mostRecentText, isStreaming]);

  useEffect(() => {
    let scrollTimeout;

    if (!isStreaming && previousStreamingRef.current && contentRef.current) {
      scrollTimeout = setTimeout(() => {
        if (!isStreaming) {
          contentRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }, 1200);
    }

    return () => clearTimeout(scrollTimeout);
  }, [isStreaming]);

  const handleDownload = async (format) => {
    if (format === 'DOCX') {
      const loadingToast = toast.loading('Preparing DOCX download...');
      try {
        const payload = {
          doc_id: documentId,
          user_id: user ? user.id : null,
          settings_object: settings
        };

        const response = await axios.post(getApiUrl(API_CONFIG.ENDPOINTS.DOWNLOAD_DOCX), payload, {
          responseType: 'blob'
        });

        // Create a download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${documentId}.docx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        toast.success('DOCX downloaded successfully');
      } catch (err) {
        console.error('Error downloading DOCX:', err);
        toast.error('Failed to download DOCX');
      } finally {
        toast.dismiss(loadingToast);
      }
    } else if (format === 'MD') {
      const loadingToast = toast.loading('Preparing MD download...');
      try {
        const payload = {
          doc_id: documentId,
          user_id: user ? user.id : null,
          settings_object: settings
        };

        const response = await axios.post(getApiUrl(API_CONFIG.ENDPOINTS.DOWNLOAD_MD), payload, {
          responseType: 'blob'
        });

        // Create a download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${documentId}.md`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        toast.success('MD downloaded successfully');
      } catch (err) {
        console.error('Error downloading MD:', err);
        toast.error('Failed to download MD');
      } finally {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleCopy = async (option) => {
    const loadingToast = toast.loading('Processing copy...');
    try {
      const payload = {
        doc_id: documentId,
        user_id: user ? user.id : null,
        fingerprint_id: !user && fingerprintId ? fingerprintId : null,
        settings_object: settings,
        auth: !!user,
      };

      const response = await axios.post(getApiUrl(API_CONFIG.ENDPOINTS.REQUEST_TEXT_UPDATE), payload);

      if (response.data && response.data.updated_text) {
        setMostRecentText(response.data.updated_text);

        if (option === 'As Markdown') {
          await navigator.clipboard.writeText(response.data.updated_text || '');
          toast.success('Markdown copied to clipboard');
        } else if (option === 'As Rich Text') {
          const html = marked(response.data.updated_text || '');
          const blob = new Blob([html], { type: 'text/html' });
          const clipboardItem = new ClipboardItem({ 'text/html': blob });
          await navigator.clipboard.write([clipboardItem]);
          toast.success('Rich Text copied to clipboard');
          
          // == Do not remove this coment == 
          // When copied as Rich Text, headings will be bolded. This needs to be fixed later.
          // == Do not remove this coment == 

        }
      } else {
        throw new Error('No updated text received');
      }
    } catch (err) {
      console.error('Error copying text:', err);
      toast.error('Failed to copy text');
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const handleViewDifferences = () => console.log('Viewing differences');
  const toggleTOC = () => setTOCOpen((prev) => !prev);
  const toggleSettings = () => setSettingsOpen((prev) => !prev);

  const tocItems = [
    'Introduction',
    'Installation',
    'Usage',
    'Features',
    'FAQ',
    'Contact',
  ];

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  return (
    <div
      className="relative flex flex-col bg-gradient-to-br from-purple-100 to-purple-50 dark:from-gray-900 dark:to-gray-800 shadow-lg overflow-hidden border-b-2 border-purple-400"
      style={{ height: '87vh' }}
    >
      <TextSettingsSidebar isOpen={isSettingsOpen} handleClose={toggleSettings} />
      <TableOfContentSidebar isOpen={isTOCOpen} handleClose={toggleTOC} tocItems={tocItems} />

      <div className="flex-1 flex flex-col" style={{ height: '80vh' }}>
        <motion.div
          className="flex justify-between items-center p-4 bg-gradient-to-r from-purple-500 to-indigo-500 text-white shadow-md"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
        >
          <h2 className="text-xl md:text-2xl font-semibold tracking-wide">
            {title}
          </h2>
          <div className="flex items-center space-x-3">
            <Dropdown
              label={
                <ArrowDownTrayIcon className="h-5 w-5 text-white hover:opacity-80 transition-opacity" />
              }
              options={[
                'DOCX',
                { label: 'PDF', disabled: true, tooltip: 'PDF download coming soon!' },
                'MD'
              ]}
              onSelect={handleDownload}
            />
            <Dropdown
              label={
                <DocumentDuplicateIcon className="h-5 w-5 text-white hover:opacity-80 transition-opacity" />
              }
              options={['As Markdown', 'As Rich Text']}
              onSelect={handleCopy}
            />
            <button
              onClick={handleViewDifferences}
              className="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-all"
              title="View Differences"
            >
              <MagnifyingGlassIcon className="h-5 w-5 text-white" />
            </button>
            <button
              onClick={toggleTOC}
              className="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-all"
              title="Table of Contents"
            >
              <BookmarkIcon className="h-5 w-5 text-white" />
            </button>
            <button
              onClick={toggleSettings}
              className="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-all"
              title="Text Settings"
            >
              <AdjustmentsHorizontalIcon className="h-5 w-5 text-white" />
            </button>
          </div>
        </motion.div>

        <motion.div
          ref={contentRef}
          className="flex-1 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 p-6 shadow-inner overflow-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          style={{ height: '100%', overflowY: 'auto' }}
        >
          <MarkdownRenderer content={mostRecentText || content} />
        </motion.div>
      </div>
    </div>
  );
};

export default OutputSection;