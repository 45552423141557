// src/components/formatter/OutputSection/MarkdownRenderer.jsx

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeHighlight from 'rehype-highlight';

import 'highlight.js/styles/atom-one-light.css'; // Light theme
import 'highlight.js/styles/atom-one-dark.css'; // Dark theme
import './syntax-highlighting.css'; // Custom CSS

// Custom plugin to remove Markdown comments
const remarkRemoveComments = () => {
  return (tree) => {
    // Filter out any HTML nodes that are comments
    tree.children = tree.children.filter(
      (node) => !(node.type === 'html' && node.value.trim().startsWith('<!--'))
    );
  };
};

const MarkdownRenderer = ({ content }) => {
  return (
    <div className="prose dark:prose-dark max-w-none">
      <ReactMarkdown
        // Existing plugins
        remarkPlugins={[remarkGfm, remarkMath, remarkRemoveComments]} // Added custom plugin
        rehypePlugins={[rehypeKatex, rehypeHighlight]}
        components={{
          code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <pre className={`${className}`}>
                <code {...props}>{children}</code>
              </pre>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          table: ({ children }) => (
            <table className="custom-table">
              {children}
            </table>
          ),
          th: ({ children }) => (
            <th className="px-4 py-2 border-b text-left text-sm font-semibold text-gray-800 dark:text-gray-200">
              {children}
            </th>
          ),
          td: ({ children }) => (
            <td className="px-4 py-2 border-b text-sm text-gray-600 dark:text-gray-300">
              {children}
            </td>
          ),
          // Custom Renderer for Block Equations
          math: ({ value }) => (
            <div className="equation-wrapper">
              <span className="katex-display">{value}</span>
            </div>
          ),
          // Custom Renderer for Inline Equations
          inlineMath: ({ value }) => (
            <span className="katex">{value}</span>
          ),
          // Custom Renderer for Unordered Lists
          ul: ({ children }) => (
            <ul className="custom-list">
              {children}
            </ul>
          ),
          // Custom Renderer for Ordered Lists
          ol: ({ children }) => (
            <ol className="custom-list">
              {children}
            </ol>
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer;