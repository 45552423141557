import React from 'react';

export const GoogleDriveIcon = () => (
  <svg className="h-4 w-4" viewBox="0 0 87.3 78" xmlns="http://www.w3.org/2000/svg">
    <path d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z" fill="#0066da"/>
    <path d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z" fill="#00ac47"/>
    <path d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z" fill="#ea4335"/>
    <path d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z" fill="#00832d"/>
    <path d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z" fill="#2684fc"/>
    <path d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z" fill="#ffba00"/>
  </svg>
);

export const MarkdownIcon = () => (
  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none">
    <path d="M3 3h18v18H3V3z" fill="#34A853" fillOpacity="0.1"/>
    <path d="M14.2 4H6.8c-.44 0-.8.36-.8.8v14.4c0 .44.36.8.8.8h10.4c.44 0 .8-.36.8-.8V8.8L14.2 4z" fill="#34A853"/>
    <path d="M8 13h8M8 17h8M8 9h5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export const PDFIcon = () => (
  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none">
    <path d="M3 3h18v18H3V3z" fill="#EA4335" fillOpacity="0.1"/>
    <path d="M14.2 4H6.8c-.44 0-.8.36-.8.8v14.4c0 .44.36.8.8.8h10.4c.44 0 .8-.36.8-.8V8.8L14.2 4z" fill="#EA4335"/>
    <text x="8" y="16" fill="white" fontSize="6" fontWeight="bold">PDF</text>
  </svg>
);

export const WordIcon = () => (
  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none">
    <path d="M3 3h18v18H3V3z" fill="#4285F4" fillOpacity="0.1"/>
    <path d="M14.2 4H6.8c-.44 0-.8.36-.8.8v14.4c0 .44.36.8.8.8h10.4c.44 0 .8-.36.8-.8V8.8L14.2 4z" fill="#4285F4"/>
    <path d="M8 13h8M8 17h8M8 9h5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export const TextFormatIcon = () => (
  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 7h16M4 12h16M4 17h10" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export const ImageFormatIcon = () => (
  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="18" rx="2" stroke="currentColor" strokeWidth="2"/>
    <circle cx="8.5" cy="8.5" r="1.5" fill="currentColor"/>
    <path d="M3 15l5-5 12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export const LayoutIcon = () => (
  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="7" height="7" stroke="currentColor" strokeWidth="2"/>
    <rect x="14" y="3" width="7" height="7" stroke="currentColor" strokeWidth="2"/>
    <rect x="3" y="14" width="7" height="7" stroke="currentColor" strokeWidth="2"/>
    <rect x="14" y="14" width="7" height="7" stroke="currentColor" strokeWidth="2"/>
  </svg>
);

export const OneDriveIcon = () => (
  <svg className="h-4 w-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.025 12.885c.105-.397.175-.81.175-1.235 0-2.645-2.145-4.79-4.79-4.79-.84 0-1.655.22-2.37.63C10.335 5.985 8.785 5 7 5c-2.76 0-5 2.24-5 5 0 .345.035.68.1 1.005C.825 11.59 0 12.785 0 14.145 0 16.275 1.725 18 3.855 18h13.29C19.275 18 21 16.275 21 14.145c0-1.045-.525-1.965-1.315-2.51l-1.66 1.25z" fill="#0364B8"/>
  </svg>
); 