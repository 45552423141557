// File: src/components/formatter/UploadModal.jsx

import React, { useState, useContext } from 'react';
import Modal from '../ui/Modal';
import FormatButton from './FormatButton';
import { AuthContext } from '../../contexts/AuthContext';
import { DocumentIcon } from '@heroicons/react/24/outline';
import io from 'socket.io-client';
import axios from 'axios';
import { getApiUrl } from '../../config/api';
import API_CONFIG from '../../config/api';
import { SessionContext } from '../../contexts/SessionContext';
import useToast from '../../utils/useToast';

const UploadModal = ({ show, handleClose, setOutputData }) => {
  const { user, fingerprintId, wordLimits } = useContext(AuthContext);
  const { setDocumentId } = useContext(SessionContext);
  const [file, setFile] = useState(null);
  const [isFormatting, setIsFormatting] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const toast = useToast();

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    setFile(selectedFile);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(
        getApiUrl('/api/extract'),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setFileDetails(response.data);
      setDocumentId(response.data.document_id);
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.response?.data?.error || 'Failed to process file');
      setFile(null);
    }
  };

  const handleFormat = () => {
    setIsFormatting(true);
    handleClose();
    let formattedContent = '';
    
    // Initialize Socket.IO connection
    const socket = io(getApiUrl(API_CONFIG.ENDPOINTS.SOCKET_FORMAT), {
      transports: ['websocket'],
      withCredentials: true
    });

    socket.on('connect', () => {
      console.log('Connected to formatting socket');
      // Send the text to be formatted along with user identification and document_id
      socket.emit('format', { 
        text: fileDetails.filename, // Send filename since backend will load content
        user_id: user?.id || null,
        fingerprint_id: !user ? fingerprintId : null,
        document_id: fileDetails.document_id // Pass document_id for image handling
      });
    });

    socket.on('formatted_chunk', (data) => {
      formattedContent += data.chunk;
      // Update the output in real-time
      setOutputData({
        title: 'Formatted Text',
        content: formattedContent,
      });
    });

    socket.on('document_id', (data) => {
      console.log('Document ID received:', data.document_id);
      setDocumentId(data.document_id);
    });

    socket.on('done', () => {
      socket.disconnect();
      setIsFormatting(false);
    });

    socket.on('error', (error) => {
      console.error('Formatting error:', error);
      toast.error(error.error || 'An error occurred during formatting');
      setIsFormatting(false);
      socket.disconnect();
    });
  };

  return (
    <>
      {!show ? null : (
        <Modal show={show} handleClose={handleClose} title="Upload Your File">
          <div className="mb-4">
            <input
              type="file"
              accept=".docx"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-md file:border-0
                file:text-sm file:font-semibold
                file:bg-purple-600 file:text-white
                hover:file:bg-purple-700
              "
              disabled={!user}
            />
            <div className="mt-2 space-y-2">
              {!user && (
                <p className="text-sm text-red-500">
                  Please sign in to upload your documents.
                </p>
              )}
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Currently supporting .docx files only. PDF support coming soon!
              </p>
            </div>
          </div>

          {/* File Preview */}
          {fileDetails ? (
            <div className="flex items-center mb-4">
              <DocumentIcon className="h-8 w-8 text-purple-600 mr-4" />
              <div>
                <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-100">
                  {fileDetails.filename}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Words: {fileDetails.word_count} | Images: {fileDetails.image_count}
                </p>
                <div className="mt-2 p-3 bg-gray-50 dark:bg-gray-800 rounded-md">
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    {fileDetails.preview}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center bg-gray-100 dark:bg-gray-700 rounded-md h-24 mb-4">
              <span className="text-gray-500 dark:text-gray-400">
                No file uploaded yet.
              </span>
            </div>
          )}

          <FormatButton 
            onClick={handleFormat} 
            disabled={!file || !fileDetails} 
            isLoading={isFormatting}
          />
        </Modal>
      )}
    </>
  );
};

export default UploadModal;