// src/components/formatter/LandingUI/MainActionArea.jsx

import React, { useState, useContext, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import PasteModal from '../PasteModal';
import UploadModal from '../UploadModal';
import OutputSection from '../OutputSection/OutputSection';
import { AuthContext } from '../../../contexts/AuthContext';
import { SessionContext } from '../../../contexts/SessionContext'; // Import SessionContext
import { GoogleDriveIcon, OneDriveIcon } from '../../ui/Icons';

const MainActionArea = () => {
  const { user } = useContext(AuthContext);
  const { mostRecentText } = useContext(SessionContext); // Destructure mostRecentText
  const outputRef = useRef(null);
  const [isPasteModalOpen, setPasteModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [outputData, setOutputData] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false); // New state for streaming

  const { setMostRecentText } = useContext(SessionContext); // Destructure setMostRecentText if needed

  const scrollToOutput = () => {
    if (outputRef.current) {
      const navbar = document.querySelector('nav');
      const navbarHeight = navbar ? navbar.offsetHeight : 0;

      const outputPosition =
        outputRef.current.getBoundingClientRect().top + window.scrollY;

      // Smooth scroll with slight easing
      window.scrollTo({
        top: outputPosition - navbarHeight * 1.3,
        behavior: 'smooth',
      });

      // Keep container animation minimal to highlight the streaming effect
      outputRef.current.style.transition = 'none'; // Disable fade/transform on the container itself
    }
  };

  const setOutputDataWithStreaming = (data) => {
    setIsStreaming(true);
    setOutputData(data);
    // Simulate streaming completion after data is set
    // Adjust the timeout as per your actual streaming logic
    setTimeout(() => setIsStreaming(false), 1000);
  };

  const handlePasteText = () => {
    setPasteModalOpen(true);
    setOutputData(null);
  };

  const handleUploadFile = () => {
    if (user) {
      setUploadModalOpen(true);
      setOutputData(null);
    }
  };

  // Effect to update outputData when mostRecentText changes
  useEffect(() => {
    if (mostRecentText) {
      setOutputData({
        title: 'Updated Document',
        content: mostRecentText,
      });
      scrollToOutput();
    }
  }, [mostRecentText]);

  return (
    <section className="flex flex-col container mx-auto py-6 px-4 h-full">
      <PasteModal 
        show={isPasteModalOpen} 
        handleClose={() => setPasteModalOpen(false)} 
        setOutputData={(data) => {
          setOutputDataWithStreaming(data);
          setTimeout(scrollToOutput, 20);
        }} 
      />
      <UploadModal 
        show={isUploadModalOpen} 
        handleClose={() => setUploadModalOpen(false)} 
        setOutputData={(data) => {
          setOutputDataWithStreaming(data);
          setTimeout(scrollToOutput, 20);
        }} 
      />

      {outputData ? (
        <div ref={outputRef} className="flex-1 overflow-hidden">
          <OutputSection 
            title={outputData.title} 
            content={outputData.content} 
            isStreaming={isStreaming}
          />
        </div>
      ) : (
        <div className="flex flex-col md:flex-row items-stretch justify-center gap-6 flex-1">
          <motion.div
            className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 w-full md:w-1/3 flex flex-col"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-100 mb-4">
              Paste Text
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4 flex-grow">
              Quickly drop your text into the formatter and let the magic happen.
            </p>
            <button
              onClick={handlePasteText}
              className="px-6 py-3 bg-purple-600 text-white font-semibold rounded-md hover:bg-purple-700 transition duration-300 mt-auto"
            >
              Paste Text
            </button>
          </motion.div>

          <motion.div
            className={`rounded-lg shadow-md p-6 w-full md:w-1/3 flex flex-col ${
              user ? 'bg-white dark:bg-gray-800' : 'bg-gray-100 dark:bg-gray-700'
            }`}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-100 mb-4">
              Upload File
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4 flex-grow">
              Upload files for advanced formatting. 
              {!user && ' Sign in to enable document uploads.'}
            </p>
            <button
              onClick={handleUploadFile}
              disabled={!user}
              className={`px-6 py-3 font-semibold rounded-md transition duration-300 mt-auto ${
                user
                  ? 'bg-purple-600 hover:bg-purple-700 text-white'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              Upload File
            </button>
          </motion.div>

          <motion.div
            className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 w-full md:w-1/3 relative overflow-hidden flex flex-col"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            {/* Blur overlay */}
            <div className="absolute inset-0 backdrop-blur-[2px] bg-white/30 dark:bg-gray-800/30 z-10"></div>
            
            <div className="relative z-20 flex flex-col h-full">
              <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-100 mb-4">
                Import from Drive
              </h2>
              <div className="flex items-center gap-3 mb-4">
                <GoogleDriveIcon />
                <OneDriveIcon />
              </div>
              <p className="text-gray-600 dark:text-gray-300 mb-4 flex-grow">
                Import documents directly from your cloud storage.
              </p>
              <div className="relative mt-auto">
                <button
                  disabled
                  className="w-full px-6 py-3 bg-gray-300 dark:bg-gray-700 text-gray-500 dark:text-gray-400 font-semibold rounded-md cursor-not-allowed"
                >
                  Coming Soon
                </button>
                <span className="absolute -top-1 -right-1 bg-purple-600 text-white text-xs px-2 py-1 rounded-full">
                  Soon
                </span>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </section>
  );
};

export default MainActionArea;