import React from 'react';
import { motion } from 'framer-motion';

const buttonVariants = {
  hover: { scale: 1.05 },
  tap: { scale: 0.95 },
};

const Button = ({ 
  onClick, 
  disabled, 
  children, 
  className = '',
  variant = 'primary', // primary, secondary, outline
  size = 'medium', // small, medium, large
}) => {
  const baseStyles = 'font-semibold rounded-md transition-colors duration-300';
  
  const variantStyles = {
    primary: 'bg-purple-600 text-white hover:bg-purple-700',
    secondary: 'bg-gray-200 text-gray-800 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600',
    outline: 'border-2 border-purple-600 text-purple-600 hover:bg-purple-50 dark:hover:bg-purple-900',
  };

  const sizeStyles = {
    small: 'px-4 py-2 text-sm',
    medium: 'px-6 py-3',
    large: 'px-8 py-4 text-lg',
  };

  const disabledStyles = disabled 
    ? 'bg-gray-300 text-gray-500 cursor-not-allowed border-gray-300 hover:bg-gray-300 dark:bg-gray-600 dark:text-gray-400' 
    : '';

  return (
    <motion.button
      onClick={onClick}
      disabled={disabled}
      className={`${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} ${disabledStyles} ${className}`}
      variants={buttonVariants}
      whileHover={!disabled && "hover"}
      whileTap={!disabled && "tap"}
    >
      {children}
    </motion.button>
  );
};

export default Button;